export default {
    avatar_index: 0,
    trophy: 0,
    name: "",
    deck: {
        hero: '1pQ3y4Mlbff53w9JUImx',
        units: [
            'Jc9Kugc2HGYL1lF5RRmZ',
            '9AuYNvoAxqsJqZ0Uhope',
            '0dOJkz7p6gxtSpXombTK',
            'UL9zqW9rTcc3e3QOL4Kr',
            'iTPb3ST5RMasxiXbiUHF',
        ],
    },
    profile: {
        victories: 0,
        defeats: 0,
        maximum_trophies: 0,
        best_wave: 0,
        unlocked_unit_count: 0
    }

}
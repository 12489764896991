<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('MOCK_USER')}}</h1>
    <div class="content-container">
      <div class="user-form">
        <div class="form-header">
          <div>{{ $t("MOCK_USER") }}</div>
          <div class="header-buttons">
            <button class="success" @click="calculateTrophy">{{ $t("CALCULATE_TROPHY") }}</button>
            <button class="success" @click="calculateWinLoss">{{ $t("CALCULATE_VICTORY_DEFEAT") }}</button>
          </div>
        </div>
        <div class="form-content">
          <div class="user-form-segment">
            <div class="form-segment-header">{{ $t("GENERAL") }}</div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("USERNAME") }}</div>
              <input v-model="mockUser.name" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("TROPHY") }}</div>
              <input v-model="mockUser.trophy" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("AVATAR_INDEX") }}</div>
              <input v-model="mockUser.avatar_index" type="number" />
            </div>
          </div>
          <div class="user-form-segment">
            <div class="form-segment-header">{{ $t("USER_PROFILE") }}</div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("VICTORIES") }}</div>
              <input v-model="mockUser.profile.victories" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("DEFEATS") }}</div>
              <input v-model="mockUser.profile.defeats" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("BEST_WAVE") }}</div>
              <input v-model="mockUser.profile.best_wave" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("MAXIMUM_TROPHIES") }}</div>
              <input v-model="mockUser.profile.maximum_trophies" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNLOCKED_UNITS") }}</div>
              <input v-model="mockUser.profile.unlocked_unit_count" type="number" />
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("WINRATE") }}</div>
              <input :value="userWinrate" disabled type="number" />
            </div>
          </div>
          <div class="user-form-segment">
            <div class="form-segment-header">{{ $t("USER_DECK") }}</div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("HERO") }}</div>
              <select v-model="mockUser.deck.hero">
                <option v-for="hero in heroes" v-bind:key="hero.id" :value="hero.id">{{ hero.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNIT") + " 1" }}</div>
              <select v-model="mockUser.deck.units[0]">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNIT") + " 2" }}</div>
              <select v-model="mockUser.deck.units[1]">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNIT") + " 3" }}</div>
              <select v-model="mockUser.deck.units[2]">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNIT") + " 4" }}</div>
              <select v-model="mockUser.deck.units[3]">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <div class="form-item-header">{{ $t("UNIT") + " 5" }}</div>
              <select v-model="mockUser.deck.units[4]">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="addMockUser" class="info">{{ $t('SUBMIT') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { createTrophyFromWinLoss, createWinLossFromTrophy } from "@/util/mockUserCalculations";
import mockUserSchema from "@/schemas/mockUser";

export default {
  name: "MockUser",
  setup() {
    const store = useStore();

    const mockUser = ref(JSON.parse(JSON.stringify(mockUserSchema)));
    const units = computed(() => store.getters["units/getUnits"])
    const heroes = computed(() => store.getters["heroes/getHeroes"])

    const mockUsers = computed(() => store.getters["mockUsers/getMockUsers"]);
    const route = useRoute();
    onMounted(() => {
      if(route.query.id && mockUsers.value.length > 0) {
        const editingHero = JSON.parse(JSON.stringify(mockUsers.value.find(x => x.id === route.query.id)))
        Object.keys(editingHero).forEach(key => {
          mockUser.value[key] = editingHero[key]
        })
      }
      store.dispatch("loader/loadingStatus", false)
    })

    watch(() => store.getters["mockUsers/getMockUsers"], mocks => {
      if(route.query.id && mocks.length > 0) {
        const editingHero = JSON.parse(JSON.stringify(mocks.find(x => x.id === route.query.id)))
        Object.keys(editingHero).forEach(key => {
          mockUser.value[key] = editingHero[key]
        })
      }
    })
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const addMockUser = () => {
      mockUser.value.profile.winrate = Math.round(mockUser.value.profile.victories * 100 / (mockUser.value.profile.victories + mockUser.value.profile.defeats));
      if(!route.query.id) {
        store.dispatch("mockUsers/addMockUser", mockUser.value);
      } else {
        store.dispatch("mockUsers/editMockUser", { userId: route.query.id, userBody: mockUser.value})
      }
    }

    const getCardName = (isUnit, id) => {
      const cardPool = isUnit ? units : heroes;
      const card = cardPool.find(x => x.id === id);
      if(!card)
        return "Not Found"
      return card.name;
    }

    const calculateTrophy = () => {
      const res = createTrophyFromWinLoss({
        victories: mockUser.value.profile.victories,
        defeats: mockUser.value.profile.defeats
      })
      mockUser.value.trophy = res;
      mockUser.value.profile.maximum_trophies = Math.max(res, mockUser.value.profile.maximum_trophies);
    }

    const calculateWinLoss = () => {
      const res = createWinLossFromTrophy(mockUser.value.trophy);
      mockUser.value.profile.victories = res.victories;
      mockUser.value.profile.defeats = res.defeats;
      mockUser.value.profile.maximum_trophies = Math.max(mockUser.value.trophy, mockUser.value.profile.maximum_trophies);
    }

    const userWinrate = computed(() => {
      if(mockUser.value.profile.victories + mockUser.value.profile.defeats === 0)
        return 0;

      return Math.round( mockUser.value.profile.victories * 100 / (mockUser.value.profile.victories + mockUser.value.profile.defeats));
    })


    return {
      mockUser,
      addMockUser,
      getCardName,
      heroes,
      units,
      mockUsers,
      calculateTrophy,
      calculateWinLoss,
      userWinrate
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
}
p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}


.user-form {
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  margin: 5px 0;
  box-sizing: border-box;
}

.form-header {
  font-size: 1.5rem;
  font-weight: 900;
  border-bottom: 2px solid black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.form-content {

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.user-form-segment {
  padding: 10px 0;
  /*border-right: 1px solid black;*/
  /*border-left: 1px solid black;*/
  border: 1px solid black;

}

.form-segment-header {
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-item {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*border-top: 1px solid black;*/
  vertical-align: center;
}

.form-item-header {
  font-weight: bold;
}

</style>